<template>
    <a-drawer v-model:visible="visible" :closable="false" @close="handleCancel" title="检验单详情">
        <template #extra>
            <a-space direction="horizontal" :size="8">
                <a-button @click="handleCancel">取消</a-button>
                <a-button type="primary" @click="handleCancel">关闭</a-button>
            </a-space>
        </template>
        <a-tabs v-model:value="activeTabKey">
            <a-tab-pane :key="1" tab="基础信息">
                <a-descriptions title="">
                    <a-descriptions-item label="检验单号">
                        {{ orderInfo?.code }}
                    </a-descriptions-item>
                    <a-descriptions-item label="单据状态" :span="2">
                        <span :style="{ color: getStatusColor(orderInfo?.status) }">
                            {{ orderInfo?.statusZh }}
                        </span>
                    </a-descriptions-item>
                    <a-descriptions-item label="项目名称">
                        {{ orderInfo?.projectName }}
                    </a-descriptions-item>
                    <a-descriptions-item label="项目编码">
                        {{ orderInfo?.projectCode }}
                    </a-descriptions-item>

                    <a-descriptions-item label="物料名称">
                        {{ orderInfo?.materialName }}
                    </a-descriptions-item>

                    <a-descriptions-item label="物料编码">
                        {{ orderInfo?.materialCode }}
                    </a-descriptions-item>

                    <a-descriptions-item label="规格型号">
                        {{ orderInfo?.categoryName }}
                    </a-descriptions-item>

                    <a-descriptions-item label="供应商" :span="3">
                        {{ orderInfo?.productName }}
                    </a-descriptions-item>
                    <a-descriptions-item label="来料数量">
                        {{ orderInfo?.checkCount }}
                    </a-descriptions-item>
                    <a-descriptions-item label="抽检数量">
                        {{ orderInfo?.samplingCount }}
                    </a-descriptions-item>
                    <a-descriptions-item label="已完成数量">
                        {{ orderInfo?.checkedMaterialCount }}
                    </a-descriptions-item>

                    <a-descriptions-item label="质检方案" :span="2">
                        {{ orderInfo?.qcPlanName }}
                    </a-descriptions-item>
                    <a-descriptions-item label="备注" :span="3">
                        {{ orderInfo?.remark }}
                    </a-descriptions-item>
                    <a-descriptions-item label="结果判定">
                        <span
                            :style="{
                                color: orderInfo?.totalTestResult == 'PASS' ? '#2BCE1D' : '#ff4d4f',
                            }"
                        >
                            {{ orderInfo?.totalTestResult }}
                        </span>
                    </a-descriptions-item>
                    <a-descriptions-item label="检验员">
                        {{ orderInfo?.checkers?.map(item => item.displayName)?.join("，") }}
                    </a-descriptions-item>
                    <a-descriptions-item label="执行总时长">
                        {{ orderInfo?.qualityWorkingHours }} h
                    </a-descriptions-item>
                </a-descriptions>
            </a-tab-pane>
            <a-tab-pane :key="2" tab="质检方案信息" force-render>
                <PlanInfo :planInfo="planInfo" />
            </a-tab-pane>
            <a-tab-pane :key="3" tab="合并检验记录">
                <MergeCheckRecord :data="mergedCheckData" />
            </a-tab-pane>
            <a-tab-pane :key="4" tab="独立检验记录">
                <SingleCheckRecord :data="singleCheckData" />
            </a-tab-pane>
        </a-tabs>
    </a-drawer>
</template>
<script>
import { defineComponent, ref, watch, reactive, toRefs, computed } from "vue";
import { apiCheckOrderDetail } from "@/api/qualityCheck";
import { getStatusColor } from "@/utils/common";
import PlanInfo from "./planInfo.vue";
import MergeCheckRecord from "./mergeCheckRecord.vue";
import SingleCheckRecord from "./singleCheckRecord.vue";

export default defineComponent({
    props: ["detailId"],
    emits: ["update:detailId"],
    components: {
        PlanInfo,
        MergeCheckRecord,
        SingleCheckRecord,
    },
    setup(props, { emit }) {
        const state = reactive({
            loading: false,
            visible: false,
            tableData: [],
            activeTabKey: 1,
            orderInfo: null,
            planInfo: null,
            mergedCheckData: [],
            singleCheckData: [],
        });

        const handleCancel = () => {
            emit("update:detailId");
        };

        const getDetailData = async id => {
            let res = await apiCheckOrderDetail(id);
            if (res.status === "SUCCESS") {
                state.orderInfo = res.data;
                const { basQcPlan, mergedCheckResultList, materialCheckResult } = res?.data;
                state.planInfo = basQcPlan;
                state.singleCheckData = materialCheckResult?.map(item => ({
                    ...res.data,
                    ...item,
                    checkResultList: item?.checkResultList?.map(uu => ({
                        ...uu,
                        baseItemInfo: basQcPlan?.items?.find(vv => vv.id == uu.basQcPlanItemId),
                    })),
                }));
                state.mergedCheckData = mergedCheckResultList?.map(item => ({
                    ...res.data,
                    ...item,
                    testingDetails: basQcPlan?.items?.find(uu => uu.id == item.basQcPlanItemId)
                        ?.testingDetails,
                }));
            }
        };

        watch(
            () => props.detailId,
            newVal => {
                state.visible = Boolean(newVal);
                if (newVal) {
                    getDetailData(newVal);
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            getStatusColor,
        };
    },
});
</script>

<style lang="less" scoped>
.title {
    margin-bottom: 10px;
}
.operate-content {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
