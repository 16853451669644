<template>
    <a-drawer
        v-model:visible="visible"
        :closable="false"
        @close="handleCancel"
        title="来料检验执行详情"
    >
        <template #extra>
            <a-space direction="horizontal" :size="8">
                <a-button @click="handleCancel">取消</a-button>
                <a-button type="primary" @click="handleCancel">关闭</a-button>
            </a-space>
        </template>
        <a-descriptions title="基础信息" :column="2">
            <a-descriptions-item label="物料名称">
                {{ detailRow?.erpBasItem?.name }}
            </a-descriptions-item>
            <a-descriptions-item label="物料编码">
                {{ detailRow?.erpBasItem?.code }}
            </a-descriptions-item>
            <a-descriptions-item label="规格型号">
                {{ detailRow?.erpBasItem?.spec }}
            </a-descriptions-item>
            <a-descriptions-item label="供应商">
                {{ detailRow?.erpBasItem?.supplier }}
            </a-descriptions-item>
            <a-descriptions-item label="物料条码">
                {{ detailRow?.materialBarcode }}
            </a-descriptions-item>
            <a-descriptions-item label="结果判定">
                <span
                    :style="{
                        color: detailRow?.testResult == 'PASS' ? '#2BCE1D' : '#ff4d4f',
                    }"
                >
                    {{ detailRow?.testResult }}
                </span>
            </a-descriptions-item>
            <a-descriptions-item label="提交时间">
                {{ detailRow?.createdTime }}
            </a-descriptions-item>
            <a-descriptions-item label="提交人">
                {{ detailRow?.creatorDisplayName }}
            </a-descriptions-item>
            <a-descriptions-item label="检验员">
                {{ detailRow?.checkers?.map(item => item.displayName)?.join("，") }}
            </a-descriptions-item>
            <a-descriptions-item label="检验时长">
                {{ detailRow?.checkDuration ?? "0" }} h
            </a-descriptions-item>
        </a-descriptions>
        <a-descriptions title="检验信息"></a-descriptions>
        <a-table
            :columns="columns"
            :data-source="detailRow?.checkResultList"
            bordered
            size="small"
            :pagination="false"
        >
            <template #bodyCell="{ column, record, index }">
                <template v-if="column.dataIndex === 'index'">
                    {{ index + 1 }}
                </template>
                <template v-if="column.dataIndex === 'code'">
                    {{ record?.baseItemInfo?.basQcItem?.code }}
                </template>
                <template v-if="column.dataIndex === 'name'">
                    {{ record?.baseItemInfo?.basQcItem?.name }}
                </template>
                <template v-if="column.dataIndex === 'typeZh'">
                    {{ record?.baseItemInfo?.basQcItem?.typeZh }}
                </template>
                <template v-if="column.dataIndex === 'testingStandards'">
                    {{ record?.baseItemInfo?.testingStandards }}
                </template>
                <template v-if="column.dataIndex === 'resultList'">
                    <div>
                        <a-table
                            style="margin: 0"
                            :dataSource="record?.baseItemInfo?.testingDetails"
                            size="small"
                            :pagination="false"
                            :columns="resultListColumns"
                        >
                            <template #bodyCell="{ column, record: resRecord, index }">
                                <template v-if="column.dataIndex === 'resultValue'">
                                    {{ record?.resultValues[resRecord?.label] }}
                                </template>
                            </template>
                        </a-table>
                    </div>
                </template>
                <template v-if="column.dataIndex === 'testResult'">
                    <span :style="{ color: record?.testResult == 'PASS' ? '#2BCE1D' : '#ff4d4f' }">
                        {{ record?.testResult }}
                    </span>
                </template>
            </template>
        </a-table>
    </a-drawer>
</template>

<script>
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
import { fileURL } from "@/utils/config";
import { showMessage } from "@/utils/common";

const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 60,
    },
    {
        title: "质检项编码",
        dataIndex: "code",
    },
    {
        title: "质检项名称",
        dataIndex: "name",
    },
    {
        title: "质检项属性",
        dataIndex: "typeZh",
        width: 100,
    },
    {
        title: "检验标准",
        dataIndex: "testingStandards",
    },
    {
        title: "结果值列表",
        dataIndex: "resultList",
    },
    {
        title: "结果判定",
        dataIndex: "testResult",
        width: 100,
    },
];
export default defineComponent({
    props: {
        detailRow: {
            default: null,
        },
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            data: null,
            previewId: null,
        });

        const handleCancel = () => {
            emit("update:detailRow", null);
        };

        const handleViewPic = (id, pics) => {
            if (!pics.length) return showMessage("info", "暂无图片");
            state.previewId = id;
        };

        watch(
            () => props.detailRow,
            newVal => {
                state.visible = Boolean(newVal);
            }
        );

        return {
            columns,
            ...toRefs(state),
            fileURL,
            handleCancel,
            handleViewPic,
            resultListColumns: [
                {
                    title: "名称",
                    dataIndex: "label",
                },
                {
                    title: "结果值",
                    dataIndex: "resultValue",
                },
                {
                    title: "单位",
                    dataIndex: "unit",
                },
            ],
        };
    },
});
</script>

<style lang="less" scoped>
.filebox {
    display: flex;
    flex-wrap: wrap;
    a {
        margin-right: 10px;
    }
}
</style>
