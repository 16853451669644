<template>
    <a-table :columns="columns" :data-source="data" bordered size="small" :pagination="false">
        <template #bodyCell="{ column, record, index }">
            <template v-if="column.dataIndex === 'index'">
                {{ index + 1 }}
            </template>
            <template v-if="column.dataIndex === 'testResult'">
                <span
                    :style="{
                        color: record?.testResult == 'PASS' ? '#2BCE1D' : '#ff4d4f',
                    }"
                >
                    {{ record?.testResult }}
                </span>
            </template>
            <template v-if="column.dataIndex === 'checkDuration'">
                {{ record.checkDuration ?? "0" }} h
            </template>
            <template v-if="column.dataIndex === 'resultList'">
                <div>
                    <a-table
                        style="margin: 0"
                        :dataSource="record?.testingDetails"
                        size="small"
                        :pagination="false"
                        :columns="resultListColumns"
                    >
                        <template #bodyCell="{ column, record: resRecord, index }">
                            <template v-if="column.dataIndex === 'resultValue'">
                                {{ record?.resultValues[resRecord?.label] }}
                            </template>
                        </template>
                    </a-table>
                </div>
            </template>
            <template v-if="column.dataIndex === 'operate'">
                <a @click="() => (detailRow = record)">详情</a>
            </template>
        </template>
    </a-table>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 60,
    },
    {
        title: "提交时间",
        dataIndex: "createdTime",
    },
    {
        title: "提交人",
        dataIndex: "creatorDisplayName",
    },
    {
        title: "检验时长",
        dataIndex: "checkDuration",
    },
    {
        title: "检验结果",
        dataIndex: "testResult",
    },
    {
        title: "结果值列表",
        dataIndex: "resultList",
    },
];
export default defineComponent({
    props: {
        data: {
            default: [],
        },
    },

    setup() {
        const state = reactive({
            detailRow: null,
        });

        return {
            columns,
            ...toRefs(state),
            resultListColumns: [
                {
                    title: "名称",
                    dataIndex: "label",
                },
                {
                    title: "结果值",
                    dataIndex: "resultValue",
                },
                {
                    title: "单位",
                    dataIndex: "unit",
                },
            ],
        };
    },
});
</script>
